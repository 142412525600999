import {emailLeadVehicle} from 'Src/api/leads'
import {saveQuote} from './DealActions'

export const emailLeadQuote = async (
  emailQuoteDispatch,
  dealState,
  customerState,
  frameContext,
  customerDispatch,
  dealDispatch,
  getSelectedRatedProducts
) => {
  const {a2zApi} = frameContext
  await emailQuoteDispatch({type: 'setSendEmail', payload: false})

  const leadVehicleId = await saveQuote(
    dealState,
    customerState,
    frameContext,
    customerDispatch,
    dealDispatch,
    getSelectedRatedProducts
  )

  if (leadVehicleId) {
    const leadVehicleResponse = await emailLeadVehicle(a2zApi, leadVehicleId)

    if (leadVehicleResponse?.data?.data?.id) {
      emailQuoteDispatch({type: 'setShowAlert', payload: true})

      return
    }
  }

  emailQuoteDispatch({type: 'setShowErrorAlert', payload: true})
}

export const emailShareLeadQuote = async (
  emails,
  dealState,
  customerState,
  frameContext,
  customerDispatch,
  dealDispatch,
  getSelectedRatedProducts
) => {
  const {a2zApi} = frameContext
  const leadVehicleId = await saveQuote(
    dealState,
    customerState,
    frameContext,
    customerDispatch,
    dealDispatch,
    getSelectedRatedProducts
  )

  if (!leadVehicleId) {
    return false
  }

  const leadVehicleResponse = await emailLeadVehicle(a2zApi, leadVehicleId, emails)

  return !!leadVehicleResponse?.data?.data?.id
}

export const updateCustomerEmail = async (frameContext, customer) => {
  const {a2zApi} = frameContext

  try {
    await a2zApi.put(`/customers/${customer.id}`, {
      data: {
        attributes: customer,
      },
    })

    return true
  } catch (error) {
    console.error('Error updating customer email:', error)

    return false
  }
}
