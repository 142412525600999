// Polyfill for Crypto.randomUUID: https://github.com/ungap/random-uuid/blob/main/index.js
const polyfill = () =>
  '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-bitwise
    (Number(c) ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (Number(c) / 4)))).toString(
      16
    )
  )

/**
 * Crypto.randomUUID
 * https://mdn.io/Crypto.randomUUID
 */
const generateUuid = () => String(crypto?.randomUUID?.() || polyfill())

export default generateUuid
