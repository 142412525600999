import {useEffect} from 'react'
import _ from 'lodash'
import {useFrameContext} from '../contexts/FrameContext'

export const useShowMessage = (condition, message) => {
  const {messages, setMessages} = useFrameContext()

  useEffect(() => {
    const messagesContainsMessage = messages.some((existingMessage) => {
      // remove functions before comparing
      const a = _.omit(existingMessage, _.functions(existingMessage))
      const b = _.omit(message, _.functions(message))
      return _.isEqual(a, b)
    })

    // if the message already exists then do not add it to the queue
    if (!condition || messagesContainsMessage) {
      return
    }

    setMessages([...messages, message])
  }, [condition])
}

export default useShowMessage
