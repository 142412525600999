import React, {useEffect, useRef, ReactNode, ElementRef} from 'react'
import classNames from 'classnames'
import {useFrameContext} from '../contexts/FrameContext'
import {useNavDispatchContext} from '../contexts/NavContext'

export const TRANSITION_DURATION = 700
const SHARED_CLASS = `tw-fixed tw-top-0 tw-h-screen tw-w-full tw-duration-${TRANSITION_DURATION}` // tw-duration-700
const MAIN_CONTENT_SHOW_CLASS = 'tw--translate-x-full'
const OVERLAY_SHOW_CLASS = '!tw-bg-black/75'

const SlideOut = ({children, open}: {children: ReactNode; open: boolean}) => {
  const overlayRef = useRef<ElementRef<'div'>>(null)
  const mainContentRef = useRef<ElementRef<'div'>>(null)
  const {isIframeVisible} = useFrameContext()
  const navDispatch = useNavDispatchContext()
  const isIframeOpenAndVisible = isIframeVisible && open

  /**
   * Traditionally, we would use the classNames package directly on the elements to toggle these classes.
   * Instead, we're intentionally using the useEffect hook here so that React first mounts the component
   * and the browser has had an opportunity to paint the DOM. We then add these classes so that the browser
   * can animate the transition.
   */
  useEffect(() => {
    const action = isIframeOpenAndVisible ? 'add' : 'remove'

    mainContentRef.current?.classList[action](MAIN_CONTENT_SHOW_CLASS)
    overlayRef.current?.classList[action](OVERLAY_SHOW_CLASS)
  }, [isIframeOpenAndVisible])

  return (
    <>
      <div
        ref={overlayRef}
        className={classNames(SHARED_CLASS, 'tw-left-0 tw-bg-black/0 tw-transition-colors')}
        onClick={() => navDispatch({type: 'closeSlideOut'})}
      />
      <div
        ref={mainContentRef}
        id="main-content"
        className={classNames(
          SHARED_CLASS,
          'tw-left-full tw-max-w-5xl tw-transform-gpu tw-bg-brand-body tw-transition-transform lg:tw-w-5/6'
        )}
      >
        {children}
      </div>
    </>
  )
}

export default SlideOut
