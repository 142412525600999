export const ACTION_DETAILS = {
  APPLICATION_TYPE: {
    INDIVIDUAL: 'application type individual',
    JOINT: 'application type joint',
  },
  BACK: 'back',
  CLOSE: 'close',
  CLOSE_BUTTON: 'close button',
  CONDITION: 'condition',
  CREDIT: {
    APPLICATION: {
      SKIP: 'skip credit application',
      START: 'start credit application',
      SUBMITTED: {
        INDIVIDUAL: 'individual credit app submitted',
        JOINT: 'joint credit app submitted',
      },
    },
    COMPLETE: 'credit complete',
    INPUT: 'credit input',
    IS_CREDIT_APP: 'is-credit-app',
    PRE_APPROVED: 'pre-approved',
    SLIDER: 'credit slider',
  },
  DEAL_TYPE_CHANGE: 'deal type',
  DO_I_QUALIFY: 'do I qualify',
  DOC_UPLOADS: {
    UPLOAD: 'upload',
  },
  DOWN_PAYMENT: 'down payment',
  EMAIL: {
    COPY: 'email me a copy',
    QUOTE: 'email quote',
  },
  ESTIMATED_PAYOFF: 'estimated payoff amount',
  INCLUDE_TRADE_IN: 'include my trade-in value',
  LEARN_MORE: {
    BASE: 'learn more',
    EXPANDED: 'learn more expanded',
  },
  LOGOUT: 'logout',
  MEET: 'meet',
  MENU: {
    ESTIMATE_TRADE: 'menu - estimate my trade',
    GET: {
      CHECK_AVAILABILITY: 'menu - get check availability',
      PRE_APPROVED: 'menu - get pre-approved',
    },
    DOC_UPLOADS: 'menu - documents upload',
    MEET_WITH_US: 'menu - meet with us',
    SCHEDULE_TEST_DRIVE: 'menu - schedule a test drive',
    SIDE_PANEL: 'side-panel',
    VIEW: {
      DEAL_DETAILS: 'menu - view deal details',
      PRICING_AND_PAYMENTS: 'menu - view pricing & payments',
      PROTECTION_PLANS: 'menu - view protection plans',
    },
  },
  PAGES: {
    CHECK_AVAILABILITY: 'check-availability',
    CREDIT: 'credit',
    CREDIT_COMPLETE: 'credit-complete',
    DOC_UPLOADS: 'uploads',
    FINALIZE: 'finalize',
    MEET: 'meet',
    PAYMENT: 'payment',
    PRODUCTS: 'products',
    REGISTER: 'register',
    SCHEDULE: 'schedule',
    TRADE: 'trade',
    TRADE_EXPERIENCE: 'trade-experience',
  },
  PHONE: 'phone',
  PRICE: {
    UNLOCK: 'unlock price',
    VIEW: 'view price and payments',
  },
  PRODUCT: {
    DESELECTED: 'product deselected',
    FILTER: {
      BACK_TO_PRODUCTS: 'product-filter-back-to-products',
      LINK: 'product-filter-link',
      SAVE: 'product-filter-save',
    },
    SELECTED: 'product selected',
    SELECTED_CATEGORIES: 'selected product categories',
  },
  REBATE: {
    SELECTED: 'rebate selected',
    TOTAL: 'rebates total',
    UNSELECTED: 'rebate unselected',
  },
  REBATES_TOTAL: 'rebates total',
  RECALCULATE_PAYMENT: 'recalculate payment',
  REGISTER: {
    CODE: {
      PASTED: 'code pasted',
      TYPED: 'code typed',
    },
    FIRST_TIME_CONTINUE: 'register first time continue',
    MATCHING_LEAD: 'register_matching-lead',
    NEW_LEAD: {
      CONFIRMATION: 'register_new-lead-confirmation',
      EDIT_INFORMATION: 'register new lead confirmation edit information',
      VERIFY: 'register new lead verify-code',
    },
    REDIRECT: 'register redirect to first registration',
    RETURN_USER: {
      CONFIRM: 'register return user confirm record',
      CONTINUE: 'register return user continue',
    },
    SEND: {
      CODE: 'register_send-code',
      NOT_THE_CUSTOMER: 'register send code not the customer',
    },
    VERIFY: {
      CODE: 'register verify code',
      TRY_AGAIN: 'register verify code try again',
    },
  },
  SCHEDULE: 'schedule',
  TRADE: {
    ADD: 'add trade',
    CAR_WORTH: "what's my car worth",
    REMOVE: 'remove trade',
    REMOVE_CONFIRMATION: {
      NO: 'remove trade confirmation - no',
      YES: 'remove trade confirmation - yes',
    },
    SKIP: 'skip trade',
  },
  ZIP_CODE: {
    BASE: 'zip code',
    CITY_COUNTY: 'zip code - city county',
    CLOSE: 'zip code close',
    CLOSED: 'zip code closed',
    OPEN: 'zip code open',
    OPENED: 'zip code opened',
  },
  SAVE_MY_DEAL: 'save my deal',
}

export const EVENTS = {
  AUTHENTICATION: {
    name: 'authentication',
    description: 'For tracking the progress of authentication',
    actions: {
      AUTHENTICATED: 'authenticated',
      REGISTERED: 'registered',
      LOGIN: 'login',
      LOGOUT: 'logout',
    },
  },
  NAVIGATION: {
    name: 'navigation',
    description: 'For tracking navigation events',
    actions: {
      NAVIGATE: 'navigate',
    },
  },
  ENGAGEMENT: {
    name: 'engagement',
    description: 'For tracking what the user does',
    actions: {
      ADDED: 'added',
      ARRIVED: 'arrived',
      CHANGE: 'change',
      CLICK: 'click',
      CLOSE: 'close',
      CONVERSION: 'conversion',
      HOVER: 'hover',
      LEAVE: 'leave',
      LOAD: 'load',
      SELECTION: 'selection',
    },
  },
  ERROR: {
    name: 'error',
    description: 'For tracking error events',
    actions: {
      ERROR: 'error',
    },
  },
}
