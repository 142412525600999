export const getInitialState = () => ({
  dirty: false,
  loading: true,
  isUpdating: true,
  isSavingQuote: false,
  displayNoProgramFoundMessage: false,
  displaySwitchToFirstProgramMessage: false,
  displayTradeNoProgramFoundMessage: false,
  dealType: 'finance',
  defaultCreditScore: 0,
  validCreditScore: false,
  creditScore: 0,
  minCreditScore: 0,
  maxCreditScore: 0,
  retailDownPayment: null,
  minRetailDownPayment: null,
  maxRetailDownPayment: null,
  noProgramsCause: null,
  leaseDownPayment: null,
  minLeaseDownPayment: null,
  maxLeaseDownPayment: null,
  retailTerm: null,
  leaseTerm: null,
  selectedLeaseMileageOption: null,
  currentDueAtSigning: 0,
  defaultZipCode: null,
  selectedRebateIds: [],
  vehicle: {},
  loaded: false,
  loadFailed: false,
  disableIncreaseDownPayment: false,
  disableDecreaseDownPayment: false,
  displayDownPaymentMessage: false,
  displayCityCountyMessage: false,
  retailDownPaymentIncrements: null,
  leaseDownPaymentIncrements: null,
  leaseFixedDownPayment: false,
})

export default function DealReducer(state, {type, payload}) {
  switch (type) {
    case 'update':
      return {
        ...state,
        dirty: true,
        ...payload,
      }
    case 'resetDeal':
      return {
        ...getInitialState(),
      }
    case 'setSavingQuote':
      return {
        ...state,
        isSavingQuote: payload,
      }
    case 'savingDealQuote':
      return {
        ...state,
        isUpdating: payload,
        isRedirectedBeforeSave: false,
      }
    default:
      throw new Error()
  }
}
