import {addThousandsSeparator} from './DataReformatters'

export const getCurrentProgram = (state) => {
  const {
    dealType,
    data,
    retailDownPayment,
    leaseDownPayment,
    retailTerm,
    leaseTerm,
    selectedLeaseMileageOption,
  } = state
  const dealTypes = data?.attributes?.dealTypes || {}
  const deal = dealTypes[dealType] || {}
  const {programs, program: cashProgram} = deal || {}
  if ((dealType === 'cash' && !cashProgram) || (dealType !== 'cash' && !programs)) {
    return null
  }
  switch (dealType) {
    case 'lease':
      return programs?.mileageGroup[selectedLeaseMileageOption]?.termsGroup[leaseTerm]
        ?.downPaymentGroup[leaseDownPayment]
    case 'finance':
      let program = programs?.termsGroup[retailTerm]?.downPaymentGroup[retailDownPayment]
      if (!program) {
        const firstKey = Object.keys(programs?.termsGroup[retailTerm]?.downPaymentGroup)[0]
        program = programs?.termsGroup[retailTerm]?.downPaymentGroup[firstKey]
      }
      return program
    default:
      // cash deal type
      return cashProgram
  }
}

export const parseCurrentData = (state) => {
  const {dealType, data, noProgramsCause} = state
  const vehicle = data?.attributes?.vehicle
  const dealTypes = data?.attributes?.dealTypes || {}
  const deal = dealTypes[dealType] || {}
  const {fees, vehiclePricing, products} = deal || {}

  const currentProgram = getCurrentProgram(state)

  return {
    dealType,
    currentProgram,
    vehicle,
    fees,
    noProgramsCause,
    products,
    vehiclePricing,
  }
}

export const validateProgram = (state, overrides, responseData) => {
  const {
    dealType,
    retailDownPayment,
    leaseDownPayment,
    retailTerm,
    leaseTerm,
    selectedLeaseMileageOption,
    data: stateData,
  } = state

  const data = responseData ?? stateData
  const selectedDealType = overrides.dealType ?? dealType
  const {
    dealTypes: {
      [selectedDealType]: {programs},
    },
  } = data?.attributes

  let downPayment
  let leaseMileageOption
  switch (selectedDealType) {
    case 'lease':
      downPayment = overrides.downPayment ?? leaseDownPayment
      leaseMileageOption = overrides.selectedMileage ?? selectedLeaseMileageOption
      return (
        !!programs &&
        !!programs?.mileageGroup[leaseMileageOption]?.termsGroup[leaseTerm]?.downPaymentGroup[
          downPayment
        ]
      )
    case 'finance':
      downPayment = overrides.downPayment ?? retailDownPayment
      return !!programs && !!programs?.termsGroup[retailTerm]?.downPaymentGroup[downPayment]
    default:
      // cash deal type
      return true
  }
}

export const defaultFirstProgram = (state, dealDispatch, overrides, responseData) => {
  const {dealType, data: stateData} = state
  const selectedDealType = overrides.dealType ?? dealType
  const data = responseData ?? stateData

  const {
    dealTypes: {
      [selectedDealType]: {programs},
    },
  } = data?.attributes

  if (!programs) {
    return false
  }

  switch (selectedDealType) {
    case 'lease':
      const selectedLeaseMileageOption = Object.keys(programs.mileageGroup)[0]
      const leaseTerm = Object.keys(programs.mileageGroup[selectedLeaseMileageOption].termsGroup)[0]
      const leaseDownPayment = parseInt(
        Object.keys(
          programs.mileageGroup[selectedLeaseMileageOption].termsGroup[leaseTerm].downPaymentGroup
        )[0]
      )

      dealDispatch({
        type: 'update',
        payload: {leaseDownPayment, leaseTerm, selectedLeaseMileageOption},
      })

      return true
    case 'finance':
      // This is for the case when trade value is close to selling price and the selected down payment is no longer valid
      const {retailTerm} = state
      const retailDownPayment = parseInt(
        Object.keys(programs.termsGroup[retailTerm].downPaymentGroup)[0]
      )
      dealDispatch({
        type: 'update',
        payload: {retailDownPayment},
      })
      return true
    default:
      // cash deal type
      return true
  }
}

export const getMinMaxDownPayment = (state, type) => {
  const {dealType, data, retailTerm, leaseTerm, selectedLeaseMileageOption} = state
  const {
    dealTypes: {
      [dealType]: {programs},
    },
  } = data?.attributes

  let minDownPayment = 0
  let maxDownPayment = 0
  let exceedingMaxLimitMessage = `The maximum ${type} for the currently selected term`
  let exceedingMinLimitMessage = `The minimum ${type} for the currently selected term`

  if (dealType === 'lease') {
    const {downPaymentGroup} = programs.mileageGroup[selectedLeaseMileageOption].termsGroup[
      leaseTerm
    ]
    const downPaymentKeys = Object.keys(downPaymentGroup)

    minDownPayment = parseInt(downPaymentKeys[0])
    maxDownPayment = parseInt(downPaymentKeys[downPaymentKeys.length - 1])

    exceedingMinLimitMessage = `${exceedingMinLimitMessage} and mileage is $${addThousandsSeparator(
      minDownPayment
    )}`
    exceedingMaxLimitMessage = `${exceedingMaxLimitMessage} and mileage is $${addThousandsSeparator(
      maxDownPayment
    )}`
  }

  if (dealType === 'finance') {
    const {downPaymentGroup} = programs.termsGroup[retailTerm]
    const downPaymentKeys = Object.keys(downPaymentGroup)

    minDownPayment = parseInt(downPaymentKeys[0])
    maxDownPayment = parseInt(downPaymentKeys[downPaymentKeys.length - 1])

    exceedingMinLimitMessage = `${exceedingMinLimitMessage} is $${addThousandsSeparator(
      minDownPayment
    )}`
    exceedingMaxLimitMessage = `${exceedingMaxLimitMessage} is $${addThousandsSeparator(
      maxDownPayment
    )}`
  }

  return {
    minDownPayment,
    maxDownPayment,
    exceedingMinLimitMessage,
    exceedingMaxLimitMessage,
  }
}

export const getTermByDealType = (state) => {
  const {leaseTerm, retailTerm, dealType} = state
  switch (dealType) {
    case 'lease':
      return leaseTerm
    case 'finance':
      return retailTerm
    default:
      // cash deal type
      return null
  }
}
