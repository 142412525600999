import { format } from "date-fns"

export const formatToInternationalNumber = (phoneNumber) => {
  if (phoneNumber.match(/^\+\d/)) return phoneNumber
  const cleaned = `${phoneNumber}`.replace(/\D/g, '')
  // eslint-disable-next-line consistent-return
  return `+1${cleaned}` // If they didn't specify a + code originally, we'll assume it's a US based number
}

export const plurality = (count) => (count !== 1 ? 's' : '')

export const formatDate = (date,dateformat = "yyyy-MM-dd") => {
  return date !== undefined && date?format(new Date(date),dateformat):"";
}