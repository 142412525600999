import {ConfigSchema} from '../validation-schemas/ConfigSchema'

/**
 * "initConfig" is a closure that is instantiated once and returns an inner (anonymous) function.
 *
 * JavaScript Closure: https://mdn.io/flex
 *
 * Problem:
 * The exported "useConfig" function is invoked numerous times from various places across
 * the application throughout its lifecycle, but we don't want to have to grab the value of
 * "config" from the parent window each time.
 *
 * Solution:
 * By wrapping the "useConfig" function inside of a closure, we can memoize (remember) the value
 * of "config" without exposing it to the global context (i.e. not attaching it to the "window"
 * object, for instance).  Here, we grab the "config" value from the parent window once and memoize
 * it, so that each subsequent invocation of "useConfig" returns the value of "config" from memory.
 */
const initConfig = () => {
  let config

  const configHandler = (event) => {
    const configFromParentWindow = event.data?.a2zDigitalRetailConfig

    if (!configFromParentWindow) {
      return
    }

    ConfigSchema.validateSync(configFromParentWindow)

    /**
     * We now have the config object from the parent window ("configFromParentWindow").
     * Assign the value of "configFromParentWindow" to our internal "config" variable and
     * remove the event listener since we don't need it anymore.
     */
    config = configFromParentWindow
    window.removeEventListener('message', configHandler)
  }

  /**
   * This anonymous inner function, once returned by invoking "initConfig()" (see below), is named
   * "useConfig", which in turn is executed multiple times.  The value of the "config" variable
   * (declared above) will persist in memory across invocations of "useConfig".
   */
  return () => {
    if (config) {
      return config
    }

    /**
     * Temporarily assign "config" a truthy value so the above condition passes and the logic
     * underneath isn't re-run.
     */
    config = {}

    if (typeof window !== 'undefined') {
      /**
       * Listen for the "message" event triggered by the parent window and execute
       * the "configHandler" function when the event is triggered.
       */
      window.addEventListener('message', configHandler)

      /**
       * Message the parent window to inform it that this window (the iframe) is ready to
       * receive the "config" value.
       */
      window.parent.postMessage('digital-retail-get-config', '*')
    }

    return config
  }
}

export const useConfig = initConfig()
