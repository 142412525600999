import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  ChangeEvent,
  BaseSyntheticEvent,
  useEffect,
} from 'react'
import {useAnalytics} from 'Src/services/analytics/useAnalytics'
import {useHistory} from 'react-router'
import {validateEmail} from 'Src/api/helpers'
import {ACTION_DETAILS} from 'Src/services/analytics/constants'
import {emailShareLeadQuote} from 'Src/actions/EmailQuoteActions'
import {useDealDispatchContext, useDealStateContext} from 'Src/contexts/DealContext'
import {useCustomerDispatchContext, useCustomerStateContext} from 'Src/contexts/CustomerContext'
import {useFrameContext} from 'Src/contexts/FrameContext'
import {useProductContext} from 'Src/contexts/ProductContext'
import useShowMessage from 'Src/utilities/useShowMessage'
import {trans} from 'Src/utilities/Helpers'
import {updateCustomerEmail} from 'Src/actions/EmailQuoteActions'

interface EmailContextType {
  emails: {yourEmail: string; otherEmail: string; [key: string]: string}
  emailErrors: {[key: string]: string}
  serverError: boolean
  getEmailAddresses: () => string[]
  handleAddAnotherEmailClick: () => void
  handleEmailBlur: (event: React.FocusEvent<HTMLInputElement>, key: string) => void
  handleEmailChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleEmailInputRemoveClick: (emailId: string) => void
  handleSendEmails: (event: BaseSyntheticEvent) => void
}

export const EmailShareQuoteContext = createContext<EmailContextType | null>(null)

interface EmailShareQuoteContextProviderProps {
  children: ReactNode
}

export const EmailShareQuoteContextProvider: React.FC<EmailShareQuoteContextProviderProps> = ({
  children,
}) => {
  const {trackEvent, events} = useAnalytics()
  const history = useHistory()
  const dealState = useDealStateContext()
  const customerState = useCustomerStateContext()
  const {customerData: customer} = customerState
  const frameContext = useFrameContext()
  const customerDispatch = useCustomerDispatchContext()
  const dealDispatch = useDealDispatchContext()
  const {getSelectedRatedProducts} = useProductContext() || {}

  const [emails, setEmails] = useState<{
    yourEmail: string
    otherEmail: string
    [key: string]: string
  }>({
    yourEmail: customer?.email ?? '',
    otherEmail: '',
  })
  const [emailErrors, setEmailErrors] = useState<{[key: string]: string}>({})
  const [showAlert, setShowAlert] = useState(false)
  const [serverError, setServerError] = useState(false)

  useEffect(() => {
    if (customer?.email) {
      setEmails((prevEmails) => ({...prevEmails, yourEmail: customer.email}))
    }
  }, [customer?.email])

  useShowMessage(showAlert, {
    title: trans('quote_not_sent'),
    description: trans('email_required_to_send'),
    type: 'error',
    onClose: () => setShowAlert(false),
  })

  const handleAddAnotherEmailClick = () => {
    setEmails((prevEmails) => ({...prevEmails, [Date.now()]: ''}))
  }

  const handleEmailInputRemoveClick = (emailId: string) => {
    setEmails((prevEmails) => {
      const currentEmails = {...prevEmails}
      delete currentEmails[emailId]

      return currentEmails
    })
    setEmailErrors((prevEmails) => {
      const currentEmails = {...prevEmails}
      delete currentEmails[emailId]

      return currentEmails
    })
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target
    setEmails((prevEmails) => ({...prevEmails, [name]: value}))
  }

  const getEmailAddresses = () => Object.values(emails).filter((email) => email !== '')

  const handleSendEmails = async (event: BaseSyntheticEvent) => {
    event.preventDefault()
    trackEvent(events.ENGAGEMENT, events.ENGAGEMENT.actions.CLICK, ACTION_DETAILS.EMAIL.QUOTE)

    const updatedErrors = {...emailErrors}

    Object.entries(emails).forEach(([key, value], index) => {
      updatedErrors[key] = validateEmail(value.trim(), index === 0)
    })

    setEmailErrors(updatedErrors)

    const allEmpty = Object.keys(emails).reduce(
      (accumulator, currentValue) => accumulator + emails[currentValue].length,
      0
    )

    if (allEmpty === 0) {
      setShowAlert(true)

      return
    }

    if (Object.values(updatedErrors)[0] !== '') {
      return
    }

    let dataToSend = {}

    if (emails.yourEmail) {
      dataToSend = {
        ...customer,
        email: emails.yourEmail,
        type: 'I',
        first_name: customer?.firstName,
        last_name: customer?.lastName,
      }

      const updateSuccess = await updateCustomerEmail(frameContext, dataToSend)

      if (!updateSuccess) {
        setServerError(true)

        return
      }

      setServerError(false)
      customerDispatch({type: 'setCustomerData', payload: {email: emails.yourEmail}})
    }

    const success = await emailShareLeadQuote(
      getEmailAddresses(),
      dealState,
      customerState,
      frameContext,
      customerDispatch,
      dealDispatch,
      getSelectedRatedProducts
    )

    if (success) {
      history.push(`/email-share-confirmation`)
    }
  }

  const handleEmailBlur = (event: React.FocusEvent<HTMLInputElement>, key: string) => {
    const errorMessages = validateEmail(event.target.value.trim(), Object.keys(emails)[0] === key)
    setEmailErrors((prevState) => ({
      ...prevState,
      [key]: errorMessages,
    }))
  }

  return (
    <EmailShareQuoteContext.Provider
      value={{
        emails,
        emailErrors,
        serverError,
        getEmailAddresses,
        handleAddAnotherEmailClick,
        handleEmailBlur,
        handleEmailChange,
        handleEmailInputRemoveClick,
        handleSendEmails,
      }}
    >
      {children}
    </EmailShareQuoteContext.Provider>
  )
}

export const useEmailShareQuoteContext = () => {
  const context = useContext(EmailShareQuoteContext)

  if (!context) {
    throw new Error(
      'useEmailShareQuoteContext must be used within an EmailShareQuoteContextProvider'
    )
  }

  return context
}
