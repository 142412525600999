export const customerInitialState = {
  codeContactMethod: 'email',
  codeReturnTo: null,
  customerData: {
    id: null,
    zipCode: '',
    cityCounty: {},
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    name: null,
  },
  userList: [],
  leadId: null,
  trade: null,
  hasActiveCreditApp: false,
  leadVehicles: [],
  previouslySavedQuote: null,
  showPreviousDealModal: false,
  existingVehicle: {paymentCalculation:null},
}

const buildCityCountyDisplay = (moreThanOneOption, oneInCity, currentInCity) =>
  (moreThanOneOption &&
    oneInCity &&
    (currentInCity ? ' (In City Limits)' : ' (Not In City Limits)')) ||
  ''

export const getCityCountyDisplayName = (payload, cityList, oneInCity) =>
  `${payload.city}, ${payload.county}${buildCityCountyDisplay(
    cityList.length > 1,
    oneInCity,
    payload.inCity
  )}`

const checkAtLeastOneInCity = (arr) => arr.some((x) => x?.attributes.inCity)

export const buildCustomerDataFromResponse = (responseData, current = {}) => {
  const {
    data: {attributes: customerData},
  } = responseData
  const {id: addressId, attributes: address} = responseData.included?.find(
    ({type}) => type === 'addresses'
  )
  const cityCounty =
    (address && {
      ...current.cityCounty,
      id: address?.region_id,
      inCity: address?.in_city,
      city: address?.city,
      county: address?.county,
    }) ||
    {}

  return {
    ...current,
    id: responseData.data.id,
    firstName: customerData.first_name || customerData.firstName,
    lastName: customerData.last_name || customerData.lastName,
    email: customerData.email,
    phone: customerData.phone,
    address: {id: addressId, ...address},
    zipCode: customerData.zip_code || customerData.zipCode,
    cityCounty,
  }
}

export default function CustomerReducer(state, action) {
  switch (action.type) {
    case 'clearCustomer':
      return {...customerInitialState}
    case 'setLeadId':
      return {
        ...state,
        leadId: action.payload,
      }
    case 'setCodeContactMethod':
      return {
        ...state,
        codeContactMethod: action.payload,
      }
    case 'setCodeReturnTo':
      return {
        ...state,
        codeReturnTo: action.payload,
      }
    case 'setCustomerData':
      return {
        ...state,
        customerData: {
          ...state.customerData,
          ...action.payload,
        },
      }
    case 'setCustomerCityCounty':
      return {
        ...state,
        customerData: {
          ...state.customerData,
          cityCounty: {
            ...action.payload.cityCounty,
            displayName: getCityCountyDisplayName(
              action.payload.cityCounty,
              action.payload.cityList,
              checkAtLeastOneInCity(action.payload.cityList)
            ),
          },
        },
      }
    case 'setUsers':
      return {
        ...state,
        userList: action.payload,
      }
    case 'setLeadVehicles':
      return {
        ...state,
        leadVehicles: action.payload,
      }
    case 'setExistingVehicle':
      return {
        ...state,
        existingVehicle: action.payload,
      }
    case 'setTrade':
      return {
        ...state,
        trade: action.payload,
      }
    case 'setHasActiveCreditApp':
      return {
        ...state,
        hasActiveCreditApp: action.payload,
      }
    case 'setPreviouslySavedQuote':
      return {
        ...state,
        previouslySavedQuote: action.payload,
      }
    case 'setShowPreviousDealModal':
      return {
        ...state,
        showPreviousDealModal: action.payload,
      }
    default:
      throw new Error(`action "${action.type}" not found`)
  }
}
