module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://ed5859efad6045b19e23fdcdad45776b@o353125.ingest.sentry.io/6066973","sampleRate":0.4,"environment":"Production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-datadog/gatsby-browser.js'),
      options: {"plugins":[],"site":"datadoghq.com","sampleRate":100,"enabled":true,"rum":{"applicationId":"22ba902c-578b-4aa4-9db2-6bf559690836","clientToken":"pubc275f1ea9c681b56aa56bb9df1b4626b","service":"a2z-digital-retail","env":"Production","trackInteractions":true},"logs":{"clientToken":"pubc275f1ea9c681b56aa56bb9df1b4626b","service":"a2z-digital-retail","env":"Production"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/a2z-icon.jpg","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"6811893f9dc56537feed85fd0be25f45"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
